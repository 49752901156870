import * as Popper from '@popperjs/core';
import * as bootstrap from 'bootstrap';
import {createApp} from 'vue';
import ChartLineByType from "../vue/components/ChartLineByType.vue";
import ChartVehiclesPerDayHannover from "../vue/components/ChartVehiclesPerDayHannover.vue";
import MapActiveJourneys from "../vue/components/MapActiveJourneys.vue";
import ChartDepotsForVehicle from "../vue/components/ChartDepotsForVehicle.vue";
import ChartLinesForVehicle from "../vue/components/ChartLinesForVehicle.vue";

import("./DataTables");

document.addEventListener("DOMContentLoaded", function () {
    window.Popper = Popper;
    window.bootstrap = bootstrap;
});

window.chartColors = {
    c1: 'rgb(27, 50, 95)',
    c2: 'rgb(14, 58, 85)',
    c3: 'rgb(0, 65, 75)',
    c4: 'rgb(12, 77, 71)',
    c5: 'rgb(36, 100, 62)',
    c6: 'rgb(60, 123, 53)',
    c7: 'rgb(84, 146, 44)',
    c8: 'rgb(108, 169, 35)',
    c9: 'rgb(120, 180, 30)',
    c10: 'rgb(146, 170, 39)',
    c11: 'rgb(172, 160, 47)',
    c12: 'rgb(198, 151, 56)',
    c13: 'rgb(224, 141, 64)',
    c14: 'rgb(250, 131, 73)',
};
window.chartBackgrounds = [
    window.chartColors.c1,
    window.chartColors.c2,
    window.chartColors.c3,
    window.chartColors.c4,
    window.chartColors.c5,
    window.chartColors.c6,
    window.chartColors.c7,
    window.chartColors.c8,
    window.chartColors.c9,
    window.chartColors.c10,
    window.chartColors.c11,
    window.chartColors.c12,
    window.chartColors.c13,
    window.chartColors.c14,
];

// init tooltips
let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
});

// vue test
document.addEventListener("DOMContentLoaded", function () {
    if (document.getElementById('charts-by-line')) {
        const app = createApp({});
        app.component('ChartLineByType', ChartLineByType);
        app.config.devtools = true;
        app.mount('#charts-by-line');
    }

    if (document.getElementById('charts-vehicles-per-day-hannover')) {
        const app2 = createApp({});
        app2.component('ChartVehiclesPerDayHannover', ChartVehiclesPerDayHannover);
        app2.config.devtools = true;
        app2.mount('#charts-vehicles-per-day-hannover');
    }

    if (document.getElementById('map-active-journeys')) {
        const app3 = createApp({});
        app3.component('MapActiveJourneys', MapActiveJourneys);
        app3.config.devtools = true;
        app3.mount('#map-active-journeys');
    }

    if (document.getElementById('chart-depots-for-vehicle')) {
        const app4 = createApp({});
        app4.component('ChartDepotsForVehicle', ChartDepotsForVehicle);
        app4.config.devtools = true;
        app4.mount('#chart-depots-for-vehicle');
    }

    if (document.getElementById('chart-lines-for-vehicle')) {
        const app4 = createApp({});
        app4.component('ChartLinesForVehicle', ChartLinesForVehicle);
        app4.config.devtools = true;
        app4.mount('#chart-lines-for-vehicle');
    }
});
