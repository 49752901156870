<script>
import Chart from "chart.js";

let chart;
export default {
    props: {
        vehicleId: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.initChart();
    },
    computed: {
        chartId() {
            return 'chart_' + this.$props.vehicleId + '_lines';
        }
    },
    methods: {
        initChart() {
            let chartId = this.chartId;
            fetch('/api/temp/vehicle/' + this.$props.vehicleId + '/lines')
                .then(response => response.json())
                .then(data => {
                    let labels = [];
                    let datasets = [];

                    data.forEach((item, index) => {
                        labels.push('Linie ' + item.line);
                        datasets.push(item.count);
                    });

                    chart = new Chart(document.getElementById(chartId).getContext('2d'), {
                        type: 'doughnut',
                        data: {
                            datasets: [{
                                backgroundColor: window.chartBackgrounds,
                                data: datasets,

                            }],
                            labels: labels
                        },
                        options: {
                            responsive: true,
                            legend: {
                                display: false,
                            }
                        }
                    });
                });
        }
    }
}
</script>

<template>
    <div class="card">
        <div class="card-body">
            <h3>Einsätze nach Linie</h3>
            <canvas :id="this.chartId"></canvas>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>

