<script>
import Chart from "chart.js";
import moment from "moment";

let chart = null;

export default {
    props: {
        since: {
            type: String,
            required: false,
            default: moment().subtract(1, 'months').format('YYYY-MM-DD')
        },
        until: {
            type: String,
            required: false,
            default: moment().format('YYYY-MM-DD')
        }
    },
    mounted() {
        this.initChart();
        this.fetchBusDataset();
        this.fetchTramDataset();
    },
    computed: {
        chartId() {
            return 'line_' + this.$props.line;
        }
    },
    methods: {
        initChart() {
            chart = new Chart(document.getElementById(this.chartId).getContext('2d'), {
                type: 'line',
                data: {
                    datasets: [
                        {
                            label: 'Anzahl Fahrzeuge (Bus)',
                            data: [],
                            backgroundColor: '#000',
                            borderColor: '#000',
                            fill: false
                        }, {
                            label: 'Anzahl Fahrzeuge (Stadtbahn)',
                            data: [],
                            backgroundColor: '#78b41e',
                            borderColor: '#78b41e',
                            fill: false
                        }
                    ]
                },
                options: {
                    responsive: true,
                    hover: {
                        mode: 'nearest',
                        intersect: true
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            type: 'time',
                            scaleLabel: {
                                display: true,
                                labelString: 'Month'
                            }
                        }],
                        yAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Value'
                            }
                        }]
                    }
                }
            });
        },
        fetchBusDataset() {
            fetch('/api/temp/stats/vehicles-per-day/bus?since=' + this.$props.since + '&until=' + this.$props.until)
                .then(response => response.json())
                .then(data => {
                    let dataset = [];

                    data.forEach((item, index) => {
                        dataset.push({
                            x: moment(item.date).format('YYYY-MM-DD'),
                            y: item.vehicles
                        });
                    });

                    chart.data.datasets[0].data = dataset;
                    chart.update();
                });
        },
        fetchTramDataset() {
            fetch('/api/temp/stats/vehicles-per-day/tram?since=' + this.$props.since + '&until=' + this.$props.until)
                .then(response => response.json())
                .then(data => {
                    let dataset = [];

                    data.forEach((item, index) => {
                        dataset.push({
                            x: moment(item.date).format('YYYY-MM-DD'),
                            y: item.vehicles
                        });
                    });

                    chart.data.datasets[1].data = dataset;
                    chart.update();
                });
        }
    }
}
</script>

<template>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <canvas :id="this.chartId"></canvas>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
