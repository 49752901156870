<script>
import Chart from "chart.js";
import moment from "moment";

export default {
    props: {
        line: {
            type: String,
            required: true,
            default: 100
        },
        since: {
            type: String,
            required: false,
            default: moment().subtract(1, 'months').format('YYYY-MM-DD')
        },
        until: {
            type: String,
            required: false,
            default: moment().format('YYYY-MM-DD')
        }
    },
    mounted() {
        this.initChart();
    },
    computed: {
        chartId() {
            return 'line_' + this.$props.line;
        }
    },
    methods: {
        initChart() {
            let line = this.$props.line;
            let chartId = this.chartId;
            fetch('/api/temp/stats/line/' + line + '/vehicles?since=' + this.$props.since + '&until=' + this.$props.until)
                .then(response => response.json())
                .then(data => {
                    let labels = [];
                    let datasets = [];

                    data.forEach((item, index) => {
                        labels.push(item.vehicleType);
                        datasets.push(item.journeys);
                    });
                    let chart = new Chart(document.getElementById(chartId).getContext('2d'), {
                        type: 'doughnut',
                        data: {
                            datasets: [{
                                backgroundColor: window.chartBackgrounds,
                                data: datasets,
                            }],
                            labels: labels
                        },
                        options: {
                            responsive: true,
                            legend: {
                                display: false,
                            }
                        }
                    });
                });
        }
    }
}
</script>

<template>
    <div class="col-md-4">
        <div class="card">
            <div class="card-body">
                <h2>Linie {{ this.$props.line }}</h2>
                <canvas :id="this.chartId"></canvas>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
