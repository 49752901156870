<script>

import L from 'leaflet';

let map;
let markers = [];
let featureGroup;
let stations = [
    638766, //Sarstedt GVH
    614052, //Langenhagen GVH
    638358, //Alte Heide
    638753, //Rethen GVH
    638601, //Wettbergen
    636293, //Altwarmbüchen
    638330, //Garbsen
    638543, //Roderbruch
    614065, //Stöcken
    //Stöcken andere 5?
    638530, //Nordhafen
    638952, //Messe Ost,
    627416, //Misburg
    636229, //Messe Nord,
    614136, //Fasanenkrug
    638321, //Empelde GVH
    614111, //Ahlem,
    628410, //Hbf
    638971, //Zoo
    614127, //Haltenhoffstr.
    638590, //Wallensteinstr
];

window.ActiveJourneys = class ActiveJourneys {

    static tramMarker(journey) {
        let color = '#cecece';
        if (journey.line.name === 'STR 10' || journey.line.name === 'STR 17') {
            color = '#7ab51d';
        } else if (journey.line.name === 'STR 1' || journey.line.name === 'STR 2' || journey.line.name === 'STR 8') {
            color = '#E2003B';
        } else if (journey.line.name === 'STR 3' || journey.line.name === 'STR 7' || journey.line.name === 'STR 9') {
            color = '#006AB3';
        } else if (journey.line.name === 'STR 4' || journey.line.name === 'STR 5' || journey.line.name === 'STR 6' || journey.line.name === 'STR 11') {
            color = '#F9B200';
        }

        return L.divIcon({
            html: '<i class="fa-solid fa-train-subway" style="line-height: 24px; font-size: 20px; color: ' + color + '"></i>',
            iconSize: [24, 24],
            className: 'text-center'
        });
    }

    static busMarker() {
        return L.divIcon({
            html: '<i class="fa-solid fa-bus-simple" style="line-height: 24px; font-size: 20px;"></i>',
            iconSize: [24, 24],
            className: 'text-muted text-center'
        });
    }

    static trainMarker(journey) {
        if (journey.line.operator.name === 'DB Fernverkehr AG' || journey.line.operator.name === 'DB Regio AG Nord') {
            return ActiveJourneys.logoMarker('DB');
        }
        if (journey.line.operator.name === 'S-Bahn Hannover (Transdev)') {
            return ActiveJourneys.logoMarker('Transdev_Hannover_GmbH');
        }
        if (journey.line.operator.name === 'WestfalenBahn') {
            return ActiveJourneys.logoMarker('WestfalenBahn');
        }
        if (journey.line.operator.name === 'metronom') {
            return ActiveJourneys.logoMarker('metronom');
        }

        return L.divIcon({
            html: '<i class="fa-solid fa-train" style="line-height: 24px; font-size: 20px;"></i>',
            iconSize: [24, 24],
            className: 'text-danger text-center'
        });
    }

    static logoMarker(name) {
        return L.divIcon({
            html: '<img src="/img/logo/' + name + '.svg" style="height: 24px;"/>',
            iconSize: [24, 24],
            className: 'text-muted text-center'
        });
    }
}


export default {
    props: {},
    mounted() {
        this.initMap();
    },
    computed: {},
    methods: {
        initMap() {
            map = L.map('map').setView([52.37451486306696, 9.738654141500207], 12);

            featureGroup = L.featureGroup().addTo(map);

            L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
                attribution: '<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>',
                maxZoom: 19,
            }).addTo(map);

            L.tileLayer('http://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png', {
                attribution: '<a href="http://www.openrailwaymap.org/">OpenRailwayMap</a>',
                minZoom: 2,
                maxZoom: 19,
                tileSize: 256
            }).addTo(map);

            for (let i in stations) {
                this.loadStation(stations[i]);
            }

            setInterval(this.refreshMap, 1000);
        },
        refreshMap() {
            let stationId = stations.shift();
            stations.push(stationId);
            this.loadStation(stationId);
        },
        loadStation(stationId) {
            fetch('https://v5.db.transport.rest/stops/' + stationId + '/arrivals?duration=60&results=100')
                .then((response) => response.json())
                .then((data) => this.refreshJourneys(data));
        },
        refreshJourneys(data) {
            let journey;

            for (let i = 0, l = data.length; i < l; i++) {
                try {
                    journey = data[i];
                    if (!journey.currentTripPosition) {
                        continue;
                    }

                    if (markers[journey.tripId]) {
                        markers[journey.tripId].setLatLng([
                            journey.currentTripPosition.latitude,
                            journey.currentTripPosition.longitude
                        ]);
                    } else {
                        let icon;
                        if (journey.line.product === 'tram') {
                            icon = ActiveJourneys.tramMarker(journey);
                        } else if (journey.line.product === 'bus') {
                            icon = ActiveJourneys.busMarker();
                        } else if (journey.line.product === 'regional' || journey.line.product === 'suburban' || journey.line.product === 'national' || journey.line.product === 'nationalExpress') {
                            icon = ActiveJourneys.trainMarker(journey);
                        } else {
                            //Nicht unterstützte Verkehrsmittel
                            continue;
                        }

                        let delay = journey.delay ? Math.round(journey.delay / 60) : 0;
                        let delayColor = delay > 4 ? 'text-danger' : 'text-info';

                        markers[journey.tripId] = L.marker([
                            journey.currentTripPosition.latitude,
                            journey.currentTripPosition.longitude
                        ], {icon: icon})
                            .addTo(featureGroup)
                            .bindPopup(
                                '<b>' + journey.line.name + '</b><br>'
                                + '<span class="' + delayColor + '">+' + delay + '</span><br>'
                                + journey.line.operator.name
                            );
                    }

                } catch (e) {
                    console.error(e);
                }
            }
        }
    }
}
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="alert alert-primary text-center">
                <span>Die angezeigten Daten basieren auf dem veröffentlichten Fahrplan inkl. Echtzeitdaten.</span>
            </div>

            <div class="card">
                <div id="map" style="height: 800px;"></div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
